import { ClassifiedItem, UserProfileType } from '@/shared/lib-api';
import { Colors } from '@/shared/util/colors';
import {
  BatteryCapacityDisplay,
  FooterLocationDisplay,
  HorsePowerOrCapacityDisplay,
  HorsePowerOrCapacityDisplay2,
  HorsepowerDisplay,
  MileageDisplay,
  PropellantDisplay,
  RegDateDisplay,
} from '@/shared/util/helpers/data-display-helper';
import { cursorPointer } from '@/shared/util/helpers/general-helper';
import { Box, Button, Divider, Grid, styled, Typography } from '@mui/material';
import Link from 'next/link';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NewVehicleTag } from '../../tags/new-vehicle-tag';
import { GalleryBoxGalleryCard } from '../../default-components/gallery/gallery-box-srp';
import { DefaultTag, SeoTag } from '../../tags/tag';
import { useBasicCarNoAuth } from '@/shared/hooks/basic-car-hook';
import { LoweredPrice, LoweredPriceTag } from '../../tags/lowered-price-tag';
import { thousandNumberSeperator } from '@/shared/util/general-helpers';
import { HasGuaranteeTag } from '../../tags/has-guarantee-tag';
import { VipLink } from '../../default-components/vip-link';
import { GetSmallImage } from '@/shared/util/helpers/image-helper';
import { EngrosTag } from '../../tags/engros-tag';

interface SeoCarGalleryCardProps {
  item?: ClassifiedItem;
  onTrackClick?: (item: ClassifiedItem) => void;
  carrousel?: boolean;
}

const MakeModel = styled(Typography)({
  fontSize: '17px',
  fontWeight: '700',
  lineHeight: '30px',
});

const Variant = styled(Typography)({
  fontSize: '17px',
  fontWeight: '500',
  lineHeight: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  minHeight: '17px',
});

const Price = styled(Typography)({
  fontSize: '20px',
  fontWeight: '700',
  color: '#1066B6',
  lineHeight: '33px',
});

const PriceLabel = styled(Typography)({
  fontSize: '15px',
  fontWeight: '400',
  color: '#1066B6',
});

const CTA = styled(Button)({
  borderRadius: '5px',
  fontSize: '16px',
  fontWeight: '700',
  whiteSpace: 'nowrap',
});

export const SeoCarGalleryCard = (props: SeoCarGalleryCardProps) => {
  const { t } = useTranslation();

  const [hover, setHover] = React.useState(false);
  const { carRoute, isLoweredInPrice, isNewVehicle, hasGuarantee, isEngros } =
    useBasicCarNoAuth({
      item: props.item,
    });

  const dataList = useMemo(() => {
    let result: string[] = [];
    if (BatteryCapacityDisplay(props.item.batteryCapacity)) {
      result.push(BatteryCapacityDisplay(props.item.batteryCapacity));
    }
    if (props.item.yearOfRegistration) {
      result.push(RegDateDisplay(props.item));
    }
    if (props.item.mileage) {
      result.push(MileageDisplay(props.item.mileage));
    }

    if (props.item.fueltype) {
      result.push(PropellantDisplay(props.item.fueltype));
    }
    if (
      HorsepowerDisplay(props.item.horsepower) &&
      !BatteryCapacityDisplay(props.item.batteryCapacity)
    ) {
      result.push(HorsepowerDisplay(props.item.horsepower));
    }
    return result;
  }, [props.item]);
  const boxRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    boxRef.current.addEventListener('mouseenter', () => setHover(true), {
      passive: true,
    });
    boxRef.current.addEventListener('mouseleave', () => setHover(false), {
      passive: true,
    });
  }, []);

  const images = useMemo(() => {
    return props.carrousel
      ? props.item?.images
          .filter((p, i) => i === 0)
          .map(p => GetSmallImage(p.folderId, p.id))
      : props.item?.images.map(p => GetSmallImage(p.folderId, p.id));
  }, [props.item.images, props.carrousel]);
  return (
    <Grid
      item
      xs={12}
      sm={6}
      onClick={() =>
        props.onTrackClick !== undefined ? props.onTrackClick(props.item) : null
      }
      md={4}
      style={{ filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15))' }}
      sx={cursorPointer}>
      <VipLink item={props.item}>
        <Box position="relative">
          {props.item?.systemData.createdDate && (
            <Box
              zIndex={200}
              top={13}
              right={13}
              position="absolute"
              display={'flex'}
              flexDirection={'row'}>
              {isNewVehicle(props.item?.systemData.createdDate.toString()) && (
                <Box marginRight={'4px'}>
                  <NewVehicleTag />
                </Box>
              )}
              {isLoweredInPrice() && (
                <Box marginRight={'4px'}>
                  <LoweredPriceTag />
                </Box>
              )}
              {hasGuarantee() && (
                <Box>
                  <HasGuaranteeTag />
                </Box>
              )}
              {isEngros() && (
                <Box display={'flex'} justifyContent="end">
                  <EngrosTag />
                </Box>
              )}
            </Box>
          )}
          {isLoweredInPrice() && (
            <Box
              width={'100%'}
              position="absolute"
              bottom={-10}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              zIndex={10}>
              <LoweredPrice carrousel={props.carrousel} item={props.item} />
            </Box>
          )}
          <GalleryBoxGalleryCard
            minHeight={0}
            items={images}
            top-border={true}
            seoSrp
            hideBullets={isLoweredInPrice()}
            alt={props.item?.headLine}
          />
        </Box>

        <Box
          ref={boxRef}
          sx={{
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px',
            padding: {
              xs: '11px 15px 10px 15px',
              md: '21px 14px 14px 19px',
            },
          }}
          bgcolor={Colors.White}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyItems={'center'}
            justifyContent={'space-between'}>
            <Box>
              <Grid container width={'100%'} justifyContent="space-between">
                <Grid item xs={12}>
                  <MakeModel>
                    {props.item?.make} {props.item?.model}
                  </MakeModel>
                  <Variant>{props.item?.alternativeVariant}</Variant>
                </Grid>
              </Grid>
            </Box>

            <Box marginTop={1} minHeight={66}>
              {dataList.map((text, textIndex) => {
                return (
                  <Box
                    display={'inline-block'}
                    marginRight="6px"
                    marginBottom="6px"
                    key={textIndex}>
                    <SeoTag text={text} />
                  </Box>
                );
              })}
            </Box>

            <Box
              display="flex"
              alignItems={'end'}
              justifyContent={'space-between'}>
              <Box>
                <Price marginTop={1.7}>{props.item?.priceFormatted}</Price>
                {isLoweredInPrice() ? (
                  <PriceLabel>
                    {`Nedsat ${thousandNumberSeperator(
                      props.item.changeInMaxPrice * -1,
                    )} kr${
                      props.carrousel
                        ? ''
                        : ` (før ${thousandNumberSeperator(
                            props.item.previousMaxPrice,
                          )} kr)`
                    }`}
                  </PriceLabel>
                ) : (
                  <PriceLabel>{props.item?.priceLabel}</PriceLabel>
                )}
              </Box>
              <Box>
                <CTA
                  sx={{ border: hover ? 'initial' : '1px solid #1066B6' }}
                  variant={hover ? 'contained' : 'outlined'}
                  color={'secondary'}>
                  {t('SeeCar')}
                </CTA>
              </Box>
            </Box>
          </Box>
        </Box>
      </VipLink>
    </Grid>
  );
};
